import {Injectable, Injector} from '@angular/core';
import {APIService, NotificationReadSerializer} from './api.service';
import {fromEvent, merge, of, ReplaySubject, Subject} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {UserAuthService} from './user-auth.service';
import {User} from '../models/user';
import {UserSettingsService} from './user-settings.service';
import {Notification} from '../portal/user-status/notification/notification.component';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {debounceTime, switchMap, take, tap} from 'rxjs/operators';
import {ToastService} from './toast.service';
import {getWebSocketUrl} from '../utils/url.utils';

const RETRY_TIMEOUT_SECS = 20;

interface DeleteNotifications {
    delete: number[];
}

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    unreadCount = new ReplaySubject<number>(1);
    notifications = new ReplaySubject<Notification[]>(1);
    broadcast = new Subject<Notification>();
    private _notifications: Notification[] = [];
    private _user: User;
    sound = new Audio('../assets/audio/to-the-point.mp3');
    private _webSocket$: WebSocketSubject<NotificationReadSerializer | DeleteNotifications>;
    private _retry$ = new Subject<void>();
    otherEvents$ = new Subject<any>();

    constructor(private api: APIService,
                private toastService: ToastService,
                private permissionsService: NgxPermissionsService,
                private userAuth: UserAuthService,
                private userSettings: UserSettingsService,
                private injector: Injector) {
        this.userAuth.user.pipe(
            tap(u => this._user = u),
            switchMap(() => merge(of(null), this._retry$.pipe(debounceTime(RETRY_TIMEOUT_SECS * 1000))))
        ).subscribe(() => {
            this._getNotifications();
        });

        // Chrome throws an error if the user has not interacted with the page
        // Before we play any sounds, so to work around this, the notification
        // Sound is muted until a click or keydown event
        this.sound.muted = true;
        merge(
            fromEvent(window, 'keyDown'),
            fromEvent(window, 'click')
        ).pipe(take(1)).subscribe(() => {
            this.sound.muted = false;
        });
    }

    private _getNotifications() {
        if (this._user && this.permissionsService.getPermission('PERMISSION_READ_NOTIFICATIONS')) {
            this.api.NotificationViewSet.list().subscribe(res => {
                this._notifications = res.results.map(x => new Notification(x, this.injector));
                this._setSocket();
                this._update();
            }, () => {
                // this.toastManager.error('An error occurred while trying to retrieve notifications', 'Error');
                this._update();
                this._retry$.next();
            });
        } else {
            if (this._webSocket$) this._webSocket$.unsubscribe();
            this._notifications = [];
            this._update();
        }
    }

    private _unreadCount(): number {
        return this._notifications.filter(x => x.unread).length;
    }

    private _push(noti: Notification | NotificationReadSerializer) {
        const notification = noti instanceof Notification ? noti : new Notification(noti, this.injector);
        this._notifications.splice(0, 0, notification);
        this._update(notification);

        if (notification.buttonAction) {
            this.toastService.buttonToast('success', notification.buttonAction, notification.message, notification.buttonText, null, null, notification.action);
        } else {
            let toastFn;
            switch (notification.color) {
                case 'danger':
                    toastFn = this.toastService.error;
                    break;
                case 'success':
                    toastFn = this.toastService.success;
                    break;
                case 'warning':
                    toastFn = this.toastService.warning;
                    break;
                default:
                    toastFn = this.toastService.info;
            }
            toastFn(notification.message).onTap.subscribe(() => {
                notification.action();
                notification.setRead();
            });
        }

        if (this.userSettings.getSetting('notifications_sounds')) {
            if (!this.sound.muted) this.sound.play();
        }
        if (this.userSettings.getSetting('auto_captcha_popup') && notification.notification_type === 'verify_captcha') {
            notification.action();
            notification.setRead();
        }
    }

    private _update(noti?: Notification) {
        this.unreadCount.next(this._unreadCount());
        this.notifications.next(this._notifications);
        if (noti) this.broadcast.next(noti);
    }

    setRead(noti: number | Notification) {
        const id = typeof noti === 'number' ? noti : noti.id;
        if (id) {
            this.api.NotificationViewSet.read(id).subscribe(res => {
                const notification = this._notifications.find(x => x.id == res.id);
                if (notification) notification.unread = false;
                this.unreadCount.next(this._unreadCount());
            }, () => this.toastService.error('An error occurred while trying to mark notification as read', 'Error'));
        }
    }

    markAllAsRead() {
        this.api.NotificationViewSet.mark_all_as_read().subscribe(() => {
            this._notifications.forEach(x => x.unread = false);
            this.unreadCount.next(this._unreadCount());
        }, () => this.toastService.error('An error occurred while trying to mark notifications as read', 'Error'));
    }

    private _setSocket() {
        if (this._webSocket$) this._webSocket$.unsubscribe();
        this._webSocket$ = webSocket(getWebSocketUrl('notifications/inbox/'));
        this._webSocket$.subscribe(
            msg => this.webSocketMsg(msg),
            err => this.webSocketErr(err),
            () => this.webSocketComplete(),
        );
    }

    webSocketMsg(msg: NotificationReadSerializer | any) {
        if (msg) {
            if (['e_prescription_task_status_changed', 'e_prescription_rx_order_status_changed'].includes(msg.type)) {
                this.otherEvents$.next(msg);
            } else if (msg.delete) {
                this._notifications = this._notifications.filter(x => !msg.delete.includes(x.id));
                this._update();
            } else {
                this._push({unread: true, ...msg});
            }
        }
    }

    webSocketErr(err) {
        this._retry$.next();
    }

    webSocketComplete() {
    }
}
