import {inject, Injectable} from '@angular/core';
import {of, Subject} from 'rxjs';
import {shareReplay, startWith, switchMap} from 'rxjs/operators';
import {APIService, ReportQueueSerializer, ReportQueueStatSerializer} from '../../../@core/api.service';
import {NotificationsService} from '../../../@core/notifications.service';
import {UserAuthService} from '../../../@core/user-auth.service';

@Injectable({
    providedIn: 'root',
})
export class DownloadQueueStatsService {
    triggerDownloadQueueStatsUpdate$ = new Subject<ReportQueueSerializer | void>();
    private _downloadQueueStatistics: ReportQueueStatSerializer;

    get downloadQueueStats(): ReportQueueStatSerializer {
        return this._downloadQueueStatistics;
    }

    private set _downloadQueueStats(value: ReportQueueStatSerializer) {
        this._downloadQueueStatistics = value;
    }

    private _notificationService = inject(NotificationsService);
    private _getDownloadQueueStats$ = this.triggerDownloadQueueStatsUpdate$.pipe(
        startWith(undefined),
        switchMap(() => APIService.ReportViewSet.stats()),
        shareReplay(1)
    );

    constructor(private userAuth: UserAuthService) {
        this.userAuth.user.pipe(
            switchMap(user => {
                if (user?.permissions.includes('PERMISSION_READ_REPORT_QUEUE')) {
                    return this._getDownloadQueueStats$;
                }
                return of(null);
            })
        ).subscribe(stats => this._downloadQueueStats = stats);

        this._notificationService.broadcast.pipe().subscribe(notification => {
            if (notification.notification_type === 'report_generated') {
                this.triggerDownloadQueueStatsUpdate$.next();
            }
        });
    }
}
